/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import DOMPurify from "dompurify";
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";

import Avatar from "app/components/commonComponent/Avatar";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { getColor, getFormatedTime } from "app/helpers/helperFunction";
import AudioVisuliazer from "./AudioVisuliazer";
import gsap from "gsap";
import AudioControls from "./AudioControls";
import { connect } from "react-redux";
import { addChat, getChatList } from "app/reducers/chat/chatAction";
import { ErrorMessage, Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { getAuth } from "app/components/pages/auth";
import {
  socketChatState,
  socketData,
  socketDataChangeState,
} from "app/reducers/websocket/websocketReducer";
import {
  getWebSocketInstance,
  useSocketChatMessage,
} from "app/helpers/webSocketHelper";
import Loader from "app/components/commonComponent/loader/Loader";
import WritableDropDown from "app/components/commonComponent/dropDown/WritableDropDown";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { Chat } from "_metronic/layout/components/chat";
import Message from "./Message";
import {
  addSocketInstance,
  addWebsocketData,
} from "app/reducers/websocket/websocketAction";

type props = {
  isDrawer?: boolean;
  id?: string | null;
  setOpenChat: any;
  openChat: boolean;
  addChatMessageDispatch: Function;
  socketDetails: socketData;
  getAllChat: Function;
  chatList: any;
  masterDataList: any;
  addSocketReceivedData: any;
  addSocket: any;
};

const initailvalue = {
  Message: "",
  FilePath: "",
  TaskId: null,
  UserId: null,
};

const TaskChat: FC<props> = ({
  isDrawer = false,
  setOpenChat,
  openChat,
  addChatMessageDispatch,
  socketDetails,
  getAllChat,
  id = null,
  chatList,
  masterDataList,
  addSocketReceivedData,
  addSocket,
}) => {
  const [selectedTaskId, setSelectedTaskId] = useState(
    id ? id.toString() : "-1"
  );
  console.log(id,selectedTaskId, "ID");
  const [formKey, setFormKey] = useState(0);
  // console.log(id, chatMessageId, "chat id");
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef<any>(null);
  const [recordingStatus, setRecordingStatus] = useState<string>("inactive");
  const [stream, setStream] = useState<MediaStream>();
  const [audioChunks, setAudioChunks] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([
    ...(masterDataList.masterData?.data?.records?.Admin ?? []),
    ...(masterDataList.masterData?.data?.records?.Article ?? []),
    ...(masterDataList.masterData?.data?.records?.TeamLeader ?? []),
  ]);
  const user = getAuth();
  // const [audio, setAudio] = useState("");
  let timeline = gsap.timeline();
  const socketSendMessage = useSocketChatMessage();
  // var taskIds: any= null;
  // useEffect(() => {
  //   console.log("CMI----");
  //   getAllChat(taskIds);
  //   console.log("call laga");
  // }, [getAllChat, taskIds]);

  useEffect(() => {
    const ws = getWebSocketInstance();

    ws.onopen = () => {
      console.log("WebSocket connected");
    };
    console.log(
      {
        socketDetails,
        getAllChat,
        chatList,
        masterDataList,
      },
      "hello world"
    );
    ws.onmessage = (event) => {
      console.log("ADD_DATA_SOCKET_STATE", event);
      addSocketReceivedData(JSON.parse(event.data));
    };

    // ws.onclose = () => {
    //   console.log("WebSocket disconnected");
    //   const newSocketConn = getWebSocketInstance(user?.CompanyId);
    //   addSocket(newSocketConn);
    // };

    addSocket(ws);

    return () => {
      ws.close();
      console.log("WebSocket closed");
    };
  }, [formKey]);

  useEffect(() => {
    getAllChat(selectedTaskId);
    //console.log(chatList, "CHAT LIST MESSAGE", selectedTaskId);
    // console.log(socketDetails.chatMessage, "CHAT MESSAGES");
    return () => {
      stream?.getTracks().forEach((t) => t.stop());
      mediaRecorder.current = null;
      setRecordingStatus("inactive");
    };
  }, [selectedTaskId, getAllChat]);

  // const handleTaskSelection = (
  //   e: any,
  //   setFieldValue: Function,
  //   getAllChat: Function
  // ) => {
  //   const taskId = e?.value ? e?.value.toString() : "-1";

  //   if (taskId !== selectedTaskId) {
  //     setFieldValue("TaskId", taskId);
  //     setSelectedTaskId(taskId);
  //     getAllChat(taskId);
  //   }
  // };

  const handleTaskSelection = (
    e: any,
    setFieldValue: Function,
    getAllChat: Function
  ) => {
    const taskId = e?.value ? e?.value.toString() : "-1";
    //setFieldValue("UserId", null)

    if (taskId !== selectedTaskId) {
      setFieldValue("TaskId", taskId);
      setSelectedTaskId(taskId);
      getAllChat(taskId);

      if (taskId === "-1" || taskId == null) {
        // No task selected -> Show all users
        setFilteredUsers([
          ...(masterDataList.masterData?.data?.records?.Admin ?? []),
          ...(masterDataList.masterData?.data?.records?.Article ?? []),
          ...(masterDataList.masterData?.data?.records?.TeamLeader ?? []),
        ]);
      } else {
        // Task selected -> Show only related users (Assignee, Manager, Expert)
        const selectedTask =
          masterDataList.masterData?.data?.records?.Tasks?.find(
            (task: any) => task.Id.toString() === taskId
          );

        if (selectedTask) {
          const { AssigneeId, ManagerId, ExpertId } = selectedTask;

          const relatedUsers = [
            ...(masterDataList.masterData?.data?.records?.Admin?.filter(
              (user: any) =>
                user.Id === AssigneeId ||
                user.Id === ManagerId ||
                user.Id === ExpertId
            ) ?? []),
            ...(masterDataList.masterData?.data?.records?.Article?.filter(
              (user: any) =>
                user.Id === AssigneeId ||
                user.Id === ManagerId ||
                user.Id === ExpertId
            ) ?? []),
            ...(masterDataList.masterData?.data?.records?.TeamLeader?.filter(
              (user: any) =>
                user.Id === AssigneeId ||
                user.Id === ManagerId ||
                user.Id === ExpertId
            ) ?? []),
          ];

          setFilteredUsers(relatedUsers);
        } else {
          setFilteredUsers([]);
        }
      }
    }
  };

  // useEffect(() => {
  //     const audio = document.getElementById('player') as HTMLAudioElement;
  //     if(audio){
  //         audio.onloadedmetadata = function() {
  //             if(audio.duration !== Infinity){
  //                 console.log(audio.duration)
  //             }
  //         };
  //     }
  // const durationSpan = document.getElementById('audio-duration') as HTMLSpanElement;
  // if (audioRef.current) {
  //     const minutes = Math.floor(audioRef.current.duration / 60);
  //     const seconds = Math.floor(audioRef.current.duration % 60);
  //     const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  //     durationSpan.innerHTML = `${minutes}:${returnedSeconds}`;
  // }
  // }, [audioRef.current, recordingStatus])

  const startRecording = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);

        if (streamData) {
          //stream
          setRecordingStatus("recording");
          const media = new MediaRecorder(streamData, {
            mimeType: "audio/webm",
          }); //stream
          mediaRecorder.current = media;
          mediaRecorder.current.onerror = (event: any) => {
            console.error(`error recording stream: ${event.error.name}`);
          };
          mediaRecorder.current.start();
          let localAudioChunks: any = [];
          mediaRecorder.current.ondataavailable = (event: any) => {
            if (typeof event.data === "undefined") return;
            if (event.data.size === 0) return;
            localAudioChunks.push(event.data);
          };
          setAudioChunks(localAudioChunks);
        }
      } catch (err: any) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const stopRecording = (
    setFieldValue: (field: string, value: string) => void
  ) => {
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
      const audioUrl = URL.createObjectURL(audioBlob);
      // setAudio(audioUrl);
      setFieldValue("FilePath", audioUrl);
      setRecordingStatus("stop");
      setAudioChunks([]);
      timeline.kill();
      stream?.getTracks().forEach((t) => t.stop());
      mediaRecorder.current = null;
      // setRecordingStatus("inactive")
    };
  };

  const deleteRecording = (
    setFieldValue: (field: string, value: string) => void
  ) => {
    // setAudio("");
    setFieldValue("FilePath", "");
    setRecordingStatus("inactive");
  };

  // const calculateTime = () => {
  //     const audio = document.getElementById('player') as HTMLAudioElement;
  //     const secs = audio?.duration;
  //     console.log(secs, audio)
  //     if (secs) {
  //         const minutes = Math.floor(secs / 60);
  //         const seconds = Math.floor(secs % 60);
  //         const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  //         return `${minutes}:${returnedSeconds}`;
  //     }
  // }

  // const sendMessage = () => {
  // const newMessage: MessageModel = {
  //   user: 2,
  //   type: "out",
  //   text: "",
  //   time: "Just now",
  // };

  // if (audio) {
  //   newMessage["messageType"] = "audio";
  //   newMessage["url"] = audio;
  // } else {
  //   newMessage["text"] = message;
  // }
  // bufferMessages.push(newMessage);
  // setMessages(bufferMessages);
  // toggleChatUpdateFlat(!chatUpdateFlag);
  // if (audio) {
  //   setAudio("");
  //   setRecordingStatus("inactive");
  // }
  // setMessage("");
  // setTimeout(() => {
  //     bufferMessages.push(messageFromClient)
  //     setMessages(() => bufferMessages)
  //     toggleChatUpdateFlat((flag) => !flag)
  // }, 1000)
  //   socket.send("heelo msg coming");
  // };

  // const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  //   if (e.keyCode === 13 && e.shiftKey === false) {
  //     e.preventDefault();
  //     sendMessage();
  //   }
  // };

  const checkForDisplayDate = (createdDate: string) => {
    const date = new Date(createdDate);
    const today = new Date();
  };
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //console.log(messagesEndRef, "MSG END REF")
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
      // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesEndRef.current]);

  const chatRef = useRef<HTMLDivElement | null>(null);

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     chatRef.current &&
  //     !chatRef.current.contains(event.target as Node) &&
  //     openChat
  //   ) {
  //     setOpenChat(false);
  //     //setSelectedTaskId(id ? id.toString() : "-1");
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // },[]);

  return (
    <ErrorBoundary>
      <div
        className={`card chat-sidebar shadow-sm ${
          openChat ? "show-chat" : "hide-chat"
        } rounded-0`}
        ref={chatRef}
      >
        <div className="card-header ps-5 align-items-center">
          <div className="card-title flex-row align-items-center gap-3">
            <div>
              <Avatar
                firstName={user?.FirstName || "User"}
                lastName=""
                background={getColor(user?.FirstName || "User").background}
                color={getColor(user?.FirstName || "User").color}
                length={1}
                rounded={true}
                size={30}
              />
            </div>
            <div className="fw-bold">
              {user?.FirstName} {user?.LastName}
            </div>
          </div>
          <div>
            <button className="btn p-0" onClick={() => setOpenChat(false)}>
              <i className="fa-solid fa-xmark fs-4 text-hover-primary" />
            </button>
          </div>
        </div>
        {chatList.loading ? (
          <div className="card-body chat-sidebar-body vertical-scroll d-flex justify-content-center align-items-center ">
            <Loader />
          </div>
        ) : (
          <div
            className="card-body chat-sidebar-body vertical-scroll"
            id={
              isDrawer
                ? "kt_drawer_chat_messenger_body"
                : "kt_chat_messenger_body"
            }
          >
            <div
              className={clsx("scroll-y me-n5 pe-5", {
                "h-300px h-lg-auto": !isDrawer,
              })}
              data-kt-element="messages"
              data-kt-drawer-width="{default:'100vw', 'sm': '500px'}"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies={
                isDrawer
                  ? "#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                  : "#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
              }
              data-kt-scroll-wrappers={
                isDrawer
                  ? "#kt_drawer_chat_messenger_body"
                  : "#kt_content, #kt_app_content, #kt_chat_messenger_body"
              }
              data-kt-scroll-offset={isDrawer ? "0px" : "5px"}
            >
              {selectedTaskId &&
                socketDetails.chatMessage
                  //chatList?.data?.records
                  ?.get(selectedTaskId.toString())
                  ?.sort((x: any, y: any) => x.Id - y.Id)
                  ?.map((message: any, index: number) => {
                    console.log(message, "MESSAGE");
                    const state = message.Type === "out" ? "info" : "primary";
                    const messageType = message.Type;
                    const templateAttr = {};
                    if (message.template) {
                      Object.defineProperty(templateAttr, "data-kt-element", {
                        value: `template-${messageType}`,
                      });
                    }
                    //console.log(chatList, "chat list");
                    const contentClass = `${
                      isDrawer ? "" : "d-flex"
                    } justify-content-${
                      messageType === "in" ? "start" : "end"
                    } mb-10`;

                    return (
                      <div
                        key={`message${index}`}
                        className={clsx("d-flex", contentClass, "mb-6", {
                          "d-none": message.template,
                        })}
                        {...templateAttr}
                      >
                        <div
                          className={clsx(
                            "d-flex flex-column align-items",
                            `align-items-${
                              messageType === "in" ? "start" : "end"
                            }`
                          )}
                        >
                          <div className="d-flex align-items-center mb-2">
                            {messageType === "in" ? (
                              <>
                                <div className="symbol  symbol-30px symbol-circle ">
                                  <Avatar
                                    firstName={message.FirstName}
                                    lastName=""
                                    background={
                                      getColor(message?.FirstName || "User")
                                        .background
                                    }
                                    color={
                                      getColor(message?.FirstName || "User")
                                        .color
                                    }
                                    length={1}
                                    rounded={true}
                                    size={30}
                                  />
                                </div>
                                <div className="ms-3" id={message.Id}>
                                  <a
                                    href="#"
                                    className="fs-6 fw-bolder text-gray-900 text-hover-primary me-1"
                                  >
                                    {message?.FirstName} {message?.LastName}
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="me-3" id={message.Id}>
                                  <a
                                    href="#"
                                    className="fs-6 fw-bolder text-gray-900 text-hover-primary ms-1"
                                  >
                                    You
                                  </a>
                                </div>
                                <div className="symbol symbol-30px symbol-circle ">
                                  <Avatar
                                    firstName={message?.FirstName || "User"}
                                    lastName=""
                                    background={
                                      getColor(message?.FirstName || "User")
                                        .background
                                    }
                                    color={
                                      getColor(message?.FirstName || "User")
                                        .color
                                    }
                                    length={1}
                                    rounded={true}
                                    size={30}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          {message.messageType === "audio" ? (
                            <>
                              <AudioControls
                                src={message.FilePath}
                                isRecordingAudio={false}
                                messageState={state}
                                index={index}
                              />
                            </>
                          ) : (
                            // <div
                            //   className={clsx(
                            //     "p-5 rounded",
                            //     `bg-light-${state}`,
                            //     "text-dark fw-bold mw-lg-400px",
                            //     `text-${messageType === "in" ? "start" : "end"}`
                            //   )}
                            //   data-kt-element="message-text"
                            //   dangerouslySetInnerHTML={{
                            //     __html: DOMPurify.sanitize(message.Message),
                            //   }}
                            // ></div>
                            <Message
                              message={{ Message: message.Message }}
                              state={state}
                              messageType={messageType}
                              isMessageToAll={message.IsMessageToAll}
                            />
                          )}
                          <div className="text-muted fs-7 mb-1">
                            {message.CreatedAt
                              ? getFormatedTime(message.CreatedAt)
                              : ""}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              <div ref={messagesEndRef} />
            </div>
          </div>
        )}
        <Formik
          enableReinitialize
          initialValues={initailvalue}
          onSubmit={async (values, { resetForm, setFieldValue }) => {
            if (values.Message !== "") {
              //console.log(selectedTaskId, 'Selected task id .');
              await addChatMessageDispatch({
                ...values,
                TaskId: selectedTaskId !== "-1" ? selectedTaskId : null,
                //TaskId: id
              });

              socketSendMessage({
                ...values,
                companyId: user?.CompanyId || 0,
                taskId: selectedTaskId,
                CreatedAt: new Date(),
                FirstName: `${user?.FirstName}`,
                LastName: `${user?.LastName}`,
                ReciepterId: values.UserId,
              });

              setFieldValue("Message", "");
              await getAllChat(selectedTaskId);
              //console.log(chatList, "CHAT LIST after submit");

              //setFormKey(prevKey => prevKey + 1);

              //resetForm();
              //getAllChat(selectedTaskId);
            }
          }}
        >
          {({ setFieldValue, values, handleSubmit, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <div
                  className="card-footer pt-4 pb-4"
                  id={
                    isDrawer
                      ? "kt_drawer_chat_messenger_footer"
                      : "kt_chat_messenger_footer"
                  }
                >
                  <div className="dropdown-container">
                    <DropDown
                      id={`dropdown-button-TaskId`}
                      className={`text-start form-control form-control-sm form-control-solid form-select ${
                        masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                      placeholder=" Task"
                      displayLoader={masterDataList.loading}
                      options={
                        masterDataList.masterData?.data?.records?.Tasks &&
                        masterDataList.masterData?.data?.records.TaskTypes
                          ? [
                              { value: null, name: "Select Task" },
                              ...masterDataList.masterData?.data?.records?.Tasks?.filter(
                                (row: { Status: number }) => row.Status !== 11
                              ).map(
                                (row: {
                                  Id: number;
                                  TaskCode: string;
                                  TaskTypeId: number;
                                }) => {
                                  return {
                                    value: row.Id,
                                    name:
                                      row.TaskCode +
                                      " " +
                                      masterDataList.masterData?.data?.records.TaskTypes.find(
                                        (x: { Id: number }) =>
                                          x.Id === row.TaskTypeId
                                      )?.Name,
                                  };
                                }
                              ),
                            ]
                          : []
                      }
                      setFieldValue={setFieldValue}
                      name="TaskId"
                      onChange={(e: any) =>
                        handleTaskSelection(e, setFieldValue, getAllChat)
                      }
                      //currentValue={{ value: values.taskId }}
                      defaultValue={{
                        value: selectedTaskId,
                        name:
                          masterDataList.masterData?.data?.records?.Tasks &&
                          masterDataList.masterData?.data?.records?.TaskTypes
                            ? masterDataList.masterData?.data?.records?.Tasks
                                .filter((row: { Id: string }) => row.Id == selectedTaskId)
                                .map((row: { Id: number; TaskCode: string; TaskTypeId: number }) => {
                                  const taskTypeName = masterDataList.masterData?.data?.records?.TaskTypes.find(
                                    (x: { Id: number }) => x.Id === row.TaskTypeId
                                  )?.Name;
                      
                                  return `${row.TaskCode} ${taskTypeName}`;
                                })[0] || "Task"
                            : "Task",
                      }}                      
                      disabled={isSubmitting}
                      showSearch={true}
                      prefix="#"
                    />
                    <ErrorMessage
                      name="TaskId"
                      component="div"
                      className="errorMsg"
                    />
                    <DropDown
                      id={`dropdown-button-UserId`}
                      className={`text-start form-control form-control-sm form-control-solid form-select ${
                        masterDataList.loading ? "display-dropdown-loader" : ""
                      }`}
                      placeholder="User"
                      setFieldValue={setFieldValue}
                      displayLoader={masterDataList.loading}
                      // options={
                      //   masterDataList.masterData?.data?.records?.Admin &&
                      //   masterDataList.masterData?.data?.records?.Article &&
                      //   masterDataList.masterData?.data?.records?.TeamLeader
                      //     ? [
                      //         { value: null, name: "Select User" },
                      //         { value: "0", name: "All" },
                      //         ...masterDataList.masterData?.data?.records?.Admin?.map(
                      //           (user: any) => {
                      //             return {
                      //               value: user.Id,
                      //               name: `${user.FirstName} ${user.LastName}`,
                      //             };
                      //           }
                      //         ),
                      //         ...masterDataList.masterData?.data?.records?.Article?.map(
                      //           (user: any) => {
                      //             return {
                      //               value: user.Id,
                      //               name: `${user.FirstName} ${user.LastName}`,
                      //             };
                      //           }
                      //         ),
                      //         ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                      //           (user: any) => {
                      //             return {
                      //               value: user.Id,
                      //               name: `${user.FirstName} ${user.LastName}`,
                      //             };
                      //           }
                      //         ),
                      //       ]
                      //     : []
                      // }
                      options={[
                        { value: null, name: "Select User" },
                        { value: "0", name: "All" },
                        ...filteredUsers.map((user: any) => ({
                          value: user.Id,
                          name: `${user.FirstName} ${user.LastName}`,
                        })),
                      ]}
                      name="UserId"
                      disabled={isSubmitting}
                      showSearch={true}
                      prefix={"@"}
                    />
                  </div>

                  {recordingStatus === "inactive" && (
                    <textarea
                      className="form-control form-control-flush mb-3"
                      rows={1}
                      data-kt-element="input"
                      placeholder="Type a message"
                      name="Message"
                      value={values.Message}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setFieldValue("Message", e.target.value)
                      }
                      onKeyDown={(event) => {
                        if (
                          event.key === "Enter" &&
                          !event.shiftKey &&
                          values.Message.trim() !== ""
                        ) {
                          event.preventDefault();
                          handleSubmit();
                        }
                      }}
                    ></textarea>
                  )}
                  {/*{recordingStatus === "recording" && (
                    <AudioVisuliazer
                      timeline={timeline}
                      recorder={mediaRecorder.current}
                      stream={stream}
                    />
                  )}
                  {recordingStatus === "stop" && (
                    <AudioControls
                      src={values.FilePath}
                      isRecordingAudio={true}
                      index={0}
                    />
                  )} */}

                  <div className="d-flex flex-stack">
                    {/* <div className="d-flex align-items-center me-2">
                      {recordingStatus === "inactive" ? (
                        <>
                          <button
                            className="btn btn-sm btn-icon btn-active-light-primary me-1"
                            type="button"
                            data-bs-toggle="tooltip"
                            title="UploadFile"
                            disabled
                          >
                            <i className="bi bi-upload fs-4 p-0"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-icon btn-active-light-primary me-1"
                            type="button"
                            onClick={startRecording}
                            title="AudioRecording"
                            disabled
                          >
                            <i className="fa-solid fa-microphone fs-4 p-0"></i>
                          </button>
                        </>
                      ) : (
                        <button
                          className={`btn btn-sm me-1 px-3 fs-6 
                          ${
                            recordingStatus === "stop"
                              ? "btn-active-light-primary"
                              : "btn-active-light-danger"
                          }`}
                          type="button"
                          onClick={
                            recordingStatus === "stop"
                              ? () => deleteRecording(setFieldValue)
                              : () => stopRecording(setFieldValue)
                          }
                        >
                          {/* <i className="fa-solid fa-microphone fs-3"></i> ----end comment
                          {recordingStatus === "stop" ? (
                            <i className="fa-solid fa-trash fs-4 p-0"></i>
                          ) : (
                            <>
                              <i className="fa-sharp fa-regular fa-circle-stop fs-3 p-0"></i>{" "}
                              Stop
                            </>
                          )}
                        </button>
                      )}
                    </div> */}
                    <button
                      className="btn btn-primary"
                      type="submit"
                      // data-kt-element='send'
                    >
                      Send
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={`pop-up-overlay ${openChat ? "" : "hidden"}`}></div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    socketDetails: state.websocketData,
    chatList: state.chatList,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addChatMessageDispatch: (chatMsg: any) => dispatch(addChat(chatMsg)),
    getAllChat: (taskId: number | null) => dispatch(getChatList(taskId)),
    addSocket: (socketInstance: WebSocket) =>
      dispatch(addSocketInstance(socketInstance)),
    addSocketReceivedData: (
      socketData: socketChatState | socketDataChangeState
    ) => dispatch(addWebsocketData(socketData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskChat);
