import { addChatService, getChatMsgListService } from "./chatService";
import { failure, request, success } from "../dispatchFunctions";
import {
  ADD_CHAT_MSG_FAILURE,
  ADD_CHAT_MSG_REQUEST,
  ADD_CHAT_MSG_SUCCESS,
  GET_CHAT_MSG_LIST_FAILURE,
  GET_CHAT_MSG_LIST_REQUEST,
  GET_CHAT_MSG_LIST_SUCCESS,
  GET_OPEN_CHAT_ID_SUCCESS,
} from "../actionTypes";
import { addChatListData } from "../websocket/websocketAction";
import { GetErrorMessage } from "../stateHelperFunction";
import { showToastMessageFailure, showToastMessageSuccess } from "app/helpers/helperFunction";

export const addChat = (data: any) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_CHAT_MSG_REQUEST));

    await addChatService(data).then(
      (result: any) => {
        dispatch(success(ADD_CHAT_MSG_SUCCESS, result));
        //showToastMessageSuccess("Chat Added");
        //dispatch(getChatList(data.taskId))
      },
      (error: any) => {
        dispatch(
          failure(ADD_CHAT_MSG_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data))
      }
    );
  };
};

export const getChatList = (taskId: number | null) => {
  return async (dispatch: any) => {
    dispatch(request(GET_CHAT_MSG_LIST_REQUEST));

    await getChatMsgListService(taskId).then(
      (result: any) => {
        dispatch(success(GET_CHAT_MSG_LIST_SUCCESS, result.data));
        dispatch(
          addChatListData({
            taskId: taskId,
            chatList: result.data.data.records,
          })
        );
      },
      (error: any) =>
        dispatch(
          failure(
            GET_CHAT_MSG_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const getOpenChat = (taskId: number | undefined) => {
  console.log(taskId);
  return async (dispatch: any) => {
    dispatch(success(GET_OPEN_CHAT_ID_SUCCESS, taskId));
  };
};
