import { FC, MouseEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { absolutePath } from "../../../../../../helpers/relativePath";
import {
  CONTACT_PERSON_DETAIL_PAGE,
  TASK_CONTACT_PERSON_DETAIL_PAGE,
} from "../../../../../../helpers/routes";
import { convert } from "app/helpers/helperFunction";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { PAYMENT_STATUS } from "app/helpers/constants";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { updateBillStatus } from "app/reducers/task/taskAction";
import { connect } from "react-redux";
import { taskData } from "app/reducers/task/taskDataInterface";
import hasPaymentAccess from "app/components/pages/auth/core/paymentAccess";
import hasPermission, {
  actionsRole,
} from "app/components/pages/auth/core/permission";

interface props {
  taskDetails: taskData;
  updateBillStatusDispatch: Function;
}

const getBillStatusArr = (billStatus: number) => {
  switch (billStatus) {
    case PAYMENT_STATUS["Billing Pending"]:
      return [
        { value: PAYMENT_STATUS["Billing Pending"], name: "Billing Pending" },
        { value: PAYMENT_STATUS["Free Of Cost"], name: "Free Of Cost" },
      ];
    case PAYMENT_STATUS["Payment Pending"]:
      return [
        { value: PAYMENT_STATUS["Payment Pending"], name: "Payment Pending" },
        { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
      ];
    case PAYMENT_STATUS["Partially Paid"]:
      return [
        { value: PAYMENT_STATUS["Partially Paid"], name: "Partially Paid" },
        { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
      ];
    case PAYMENT_STATUS["Proforma Generated"]:
      return [
        {
          value: PAYMENT_STATUS["Proforma Generated"],
          name: "Proforma Generated",
        },
      ];
    case PAYMENT_STATUS["Fully Paid"]:
      return [{ value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" }];
    case PAYMENT_STATUS["Free Of Cost"]:
      return [{ value: PAYMENT_STATUS["Free Of Cost"], name: "Free Of Cost" }];
    default:
      return [{ value: billStatus, name: PAYMENT_STATUS[billStatus] }];
  }
};

const TaskClientDetails: FC<props> = ({
  taskDetails,
  updateBillStatusDispatch,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const onChangeBillStatus = (e: MouseEvent) => {
    setLoading(true);
    updateBillStatusDispatch(
      [params.id],
      (e.target as HTMLLIElement).dataset.id,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <ErrorBoundary>
      <div className="client-detail-container col-12">
        <div className="card">
          <div className="card-header pt-5 align-items-center border-0">
            <div className="card-title flex-column">
              <div className="fw-bold">Client Details</div>
            </div>
            <div className="d-flex gap-4 align-items-center flex-wrap">
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    navigate(
                      absolutePath(
                        `${TASK_CONTACT_PERSON_DETAIL_PAGE}/${taskDetails.ClientId}`
                      )
                    );
                  }}
                >
                  View Contact Person
                </button>
              </div>
              {/* {hasPaymentAccess() && (
                <div>
                  <DropDown
                    placeholder="Select Bill Status"
                    className={`form-select form-select-sm form-select-white show text-start w-175px bill-status-border-${
                      taskDetails.BillingStatus
                    } ${loading ? "display-dropdown-loader" : ""}`}
                    options={getBillStatusArr(taskDetails.BillingStatus)}
                    apiCallDispatch={onChangeBillStatus}
                    displayLoader={loading}
                    currentValue={{
                      value: taskDetails.BillingStatus,
                      name: PAYMENT_STATUS[taskDetails.BillingStatus],
                    }}
                    defaultValue={{
                      value: taskDetails.BillingStatus,
                      name: PAYMENT_STATUS[taskDetails.BillingStatus],
                    }}
                  />
                </div>
              )} */}
            </div>
          </div>
          <div className="card-body pt-6 fs-6">
            <div className="row mb-4 align-items-center">
              <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                Client
              </div>
              <div className="col-5">{taskDetails.ClientName}</div>
            </div>
            {hasPaymentAccess() && (
              <>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Date
                  </div>
                  <div className="col-5">
                    {taskDetails.BillDetails?.Date
                      ? convert(taskDetails.BillDetails.Date)
                      : "-"}
                  </div>
                </div>

                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Bill Number
                  </div>
                  <div className="col-5">
                    {taskDetails.BillDetails?.BillNumber || "-"}
                  </div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Paid Amount
                  </div>
                  <div className="col-5">
                    {taskDetails.BillDetails?.PaidAmount || "-"}
                  </div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Billing Status
                  </div>
                  <div
                    className={`badge col-5 w-auto bill-status-${taskDetails.BillingStatus}`}
                  >
                    {PAYMENT_STATUS[taskDetails.BillingStatus]}
                  </div>
                </div>
                <div className="row mb-4 align-items-center">
                  <div className="col-6 col-md-3 col-sm-4 col-xl-6 col-xxl-4 fw-semibold text-gray-600">
                    Billing Amount
                  </div>
                  <div className="col-5">
                    {taskDetails.BillDetails?.TotalAmount || "-"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateBillStatusDispatch: (
      taskId: Array<number>,
      billStatus: number,
      successCallback: Function,
      failureCallback: Function
    ) =>
      dispatch(
        updateBillStatus(taskId, billStatus, successCallback, failureCallback)
      ),
  };
};

export default connect(null, mapDispatchToProps)(TaskClientDetails);
