import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { FC, useRef, useState } from "react";

import DropDown from "../../../commonComponent/dropDown/DropDown";
import FilterButton from "../../../commonComponent/tableFilter/FilterButton";
import {
  getFilterQueryString,
  getStatus,
  getStatusArr,
} from "../../../../helpers/helperFunction";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { useGetFilterObject, useOutsideAlerter } from "app/helpers/customHooks";
import { PAYMENT_STATUS } from "app/helpers/constants";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";

interface props {
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

const OPTIONS = [
  { value: "true", name: "Repetative" },
  { value: "false", name: "One Time Task" },
];

const BILL_STATUS_OPTION = [
  { value: PAYMENT_STATUS["Billing Pending"], name: "Billing Pending" },
  { value: PAYMENT_STATUS["Proforma Generated"], name: "Proforma Generated" },
  { value: PAYMENT_STATUS["Payment Pending"], name: "Payment Pending" },
  { value: PAYMENT_STATUS["Partially Paid"], name: "Partially Paid" },
  { value: PAYMENT_STATUS["Fully Paid"], name: "Fully Paid" },
  { value: PAYMENT_STATUS["Free Of Cost"], name: "Free Of Cost" },
];

const TaskFilter: FC<props> = ({ masterDataList }) => {
  const { filterObj, searchObj, setSearchParam } = useGetFilterObject();
  const [showDropDown, setShowDropDown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowDropDown);

  const resetHandler = (resetForm: any) => {
    delete searchObj.Filters;
    resetForm();
    setSearchParam({ ...searchObj, PageNumber: "1" });
  };

  return (
    <div className="position-relative" ref={wrapperRef}>
      <FilterButton
        setShowDropDown={setShowDropDown}
        showDropDown={showDropDown}
      />
      <div
        className={`shadow-sm card w-300px w-sm-400px w-md-500px task-filter ${
          showDropDown ? "" : "hidden"
        }`}
      >
        <div className="p-7">
          <div className="fs-5 text-dark fw-bolder">Filter</div>
        </div>
        <div className="separator border-gray-200"></div>
        <Formik
          enableReinitialize
          initialValues={filterObj}
          onSubmit={async (values: any) => {
            setSearchParam({
              ...searchObj,
              PageNumber: "1",
              Filters: getFilterQueryString(values),
            });
          }}
        >
          {({ values, isSubmitting, setFieldValue, resetForm }) => {
            return (
              <Form noValidate className="form">
                <div className="mb-10 px-9 pt-3">
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Task
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Task"
                          displayLoader={masterDataList.loading}
                          options={masterDataList.masterData?.data?.records?.TaskTypes?.map(
                            (type: any) => {
                              return { value: type.Id, name: type.Name };
                            }
                          )}
                          setFieldValue={setFieldValue}
                          name="TypeId"
                          currentValue={{
                            value: values.TypeId,
                            name: masterDataList.masterData?.data?.records?.TaskTypes?.find(
                              (type: { Id: number; Name: string }) =>
                                String(type.Id) === String(values.TypeId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: values.TypeId,
                            name: masterDataList.masterData?.data?.records?.TaskTypes?.find(
                              (type: { Id: number; Name: string }) =>
                                String(type.Id) === String(values.TypeId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Client
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Client"
                          displayLoader={masterDataList.loading}
                          options={Array.from(
                            new Map(
                              masterDataList.masterData?.data?.records?.Clients?.map(
                                (client: { Id: number; Name: string }) => [client.Id, { value: client.Id, name: client.Name }]
                              ) ?? []
                            ).values()
                          )}
                          setFieldValue={setFieldValue}
                          name="ClientId"
                          currentValue={{
                            value: values.ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          defaultValue={{
                            value: values.ClientId,
                            name: masterDataList.masterData?.data?.records?.Clients?.find(
                              (client: { Id: number; Name: string }) =>
                                String(client.Id) === String(values.ClientId)
                            )?.Name,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Article
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          displayLoader={masterDataList.loading}
                          placeholder="Select Article"
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: `${assignee.FirstName} ${assignee.LastName}`,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.Article?.map(
                                    (assignee: any) => {
                                      return {
                                        value: `${assignee.FirstName} ${assignee.LastName}`,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: `${assignee.FirstName} ${assignee.LastName}`,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name="PersonAssigned"
                          currentValue={{
                            value: values.PersonAssigned,
                            name: values.PersonAssigned,
                          }}
                          defaultValue={{
                            value: values.PersonAssigned,
                            name: values.PersonAssigned,
                          }}
                          showSearch={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Work Status
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Status"
                          options={getStatusArr(11)}
                          setFieldValue={setFieldValue}
                          name="Status"
                          currentValue={{
                            value: values.Status,
                          }}
                          defaultValue={{
                            value: values.Status,
                            name: values.Status
                              ? getStatus(Number(values.Status)).name
                              : "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Bill Status
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Bill Status"
                          options={BILL_STATUS_OPTION}
                          setFieldValue={setFieldValue}
                          name="BillingStatus"
                          currentValue={{
                            value: values.BillingStatus,
                          }}
                          defaultValue={{
                            value: values.BillingStatus,
                            name: values.BillingStatus
                              ? PAYMENT_STATUS[Number(values.BillingStatus)]
                              : "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6">
                        Repetation Type
                      </label>

                      <div className="fv-row">
                        <DropDown
                          className="text-start form-control form-control-lg form-control-solid form-select"
                          placeholder="Select Type"
                          options={OPTIONS}
                          setFieldValue={setFieldValue}
                          name="IsRepeatative"
                          currentValue={{
                            value: values.IsRepeatative,
                          }}
                          defaultValue={{
                            value: values.IsRepeatative,
                            name:
                              values.IsRepeatative === "true"
                                ? "Repetative"
                                : "One Time Task",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Start Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="startDate"
                          placeholder="Start Date"
                          currentValue={
                            values.startDate ? new Date(values.startDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        End Date
                      </label>

                      <div className="">
                        <CustomDatePicker
                          name="endDate"
                          placeholder="End Date"
                          currentValue={
                            values.endDate ? new Date(values.endDate) : null
                          }
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end pb-6 px-6 gap-3">
                  <ResetButton
                    name="Reset"
                    className="btn btn-light btn-active-light-primary btn-sm"
                    onClickCallback={() => {
                      resetHandler(resetForm);
                      setShowDropDown(false);
                    }}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Apply"
                    className="btn btn-primary btn-sm"
                    onClickCallback={() => setShowDropDown(false)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};
export default connect(mapStateToProps)(TaskFilter);
