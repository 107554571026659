import {
  ADD_CHAT_MSG_API_ENDPOINT,
  GET_ALL_CHAT_MSG_API_ENDPOINT,
} from "app/helpers/config";
import { axiosInstance } from "../axiosInstance";

export const addChatService = async (data: any) => {
  return await axiosInstance.post(ADD_CHAT_MSG_API_ENDPOINT, data);
};

export const getChatMsgListService = (taskId: number | null) => {
  return axiosInstance.get(`${GET_ALL_CHAT_MSG_API_ENDPOINT}?taskId=${taskId}`);
};
