import { FC, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { HeaderWrapper } from "./components/header";
import { ScrollTop } from "./components/scroll-top";
import { Content } from "./components/content";
import {
  DrawerMessenger,
  ActivityDrawer,
  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from "../partials";
import { PageDataProvider } from "./core";
import { reInitMenu } from "../helpers";
import { Sidebar } from "./components/sidebar";
// import { ToolbarWrapper } from "./components/toolbar";
// import ErrorBoundary from "app/helpers/ErrorBoundary";
import { connect } from "react-redux";
import { getMasterData } from "app/reducers/masterData/masterDataAction";
import { getWebSocketInstance } from "app/helpers/webSocketHelper";
import { getAuth } from "app/components/pages/auth";
import {
  addSocketInstance,
  addWebsocketData,
} from "app/reducers/websocket/websocketAction";
import {
  socketChatState,
  socketDataChangeState,
} from "app/reducers/websocket/websocketReducer";
import DataChangedMessage from "app/components/commonComponent/dataChangedMessage/DataChangedMessage";
import store from "app/store/store";
import { Chat } from "./components/chat";

interface props {
  getMasterDataList: Function;
  addSocket: Function;
  addSocketReceivedData: Function;
}
const MasterLayout: FC<props> = ({
  getMasterDataList,
  addSocket,
  addSocketReceivedData,
}) => {
  const location = useLocation();

  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  useEffect(() => {
    getMasterDataList();
    // const ws = getWebSocketInstance();

    // ws.onopen = () => {
    //   console.log("WebSocket connected");
    // };

    // ws.onmessage = (event) => {
    //   console.log("ADD_DATA_SOCKET_STATE",event)
    //   addSocketReceivedData(JSON.parse(event.data));
    // };

    // ws.onclose = () => {
    //   console.log("WebSocket disconnected");
    //   const newSocketConn = getWebSocketInstance(user?.CompanyId);
    //   addSocket(newSocketConn);
    // };

    // addSocket(ws);

    return () => {
      // ws.close();
      // console.log("WebSocket closed");
    };
  }, []);

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          <div
            className="app-page flex-column flex-column-fluid"
            id="kt_app_page"
          >
            <HeaderWrapper />
            <div
              className="app-wrapper flex-column flex-row-fluid"
              id="kt_app_wrapper"
            >
              <Sidebar />
              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                <div className="d-flex flex-column flex-column-fluid">
                  {/* <ToolbarWrapper /> */}
                  <Content>
                    <DataChangedMessage />
                    <Outlet />
                  </Content>
                </div>
                {/* <FooterWrapper /> */}
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <RightToolbar /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMasterDataList: () => dispatch(getMasterData()),
    addSocket: (socketInstance: WebSocket) =>
      dispatch(addSocketInstance(socketInstance)),
    addSocketReceivedData: (
      socketData: socketChatState | socketDataChangeState
    ) => dispatch(addWebsocketData(socketData)),
  };
};

export default connect(null, mapDispatchToProps)(MasterLayout);
