import { connect } from "react-redux";
import { FC, SetStateAction, useEffect, useState } from "react";

import { downloadFile } from "app/reducers/task/taskAction";
import { KTSVG } from "_metronic/helpers";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { convert } from "app/helpers/helperFunction";
import { billData } from "app/reducers/billing/billingDataInterface";
import { GeneratePdfByClick } from "./GetUrlForPdf";
import AddTransactionShrt, {
  RECEIPT_TYPE_OPTIONS,
} from "../../transaction/component/AddTransactionShrt"
interface props {
  row: billData;
  setBill: SetStateAction<any>;
  downloadFileDispatch: Function;
}

const BillingTableRow: FC<props> = ({ downloadFileDispatch, row, setBill }) => {
  const initialValues = {
    bookId: undefined,
    amount: "",
    narration: "",
    type: RECEIPT_TYPE_OPTIONS[2],
    taskId: undefined,
    billId: row.Id,
    expenseId: undefined,
    isPayment: "false",
    clientId: row.ClientId,
    firmId: undefined,
    isCashBook: "true",
    dueAmount: row.TotalAmount - row.PaidAmount,
    billNumber: row.BillNumber,
    date: row.Date,
  };
  const [showTransactionModal, setShowTransactionModal] = useState(false);


  const closeModal = () => {
      setShowTransactionModal(false);
  };
  
  const [loading, setLoading] = useState(false);

  const handleEditData = () => {
    setBill({ show: true, edit: true, billId: row.Id });
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.BillNumber}</td>
      <td className="">{row.ClientName}</td>
      <td className="">
        {row.Tasks.map((task: { TaskCode: string }) => task.TaskCode).join(
          ","
        ) || "-"}
      </td>
      <td className="">{row?.Firm?.Name || "-"}</td>
      <td className="">{row.TotalAmount}</td>
      <td className="">{row.TotalAmount - row.PaidAmount}</td>
      <td>{row?.Date ? convert(row.Date) : "-"}</td>
      <td>
        <div className={`badge badge-sm bill-status-${row.PaymentStatus}`}>
          {PAYMENT_STATUS[row.PaymentStatus]}
        </div>
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          {/* <button
            type="button"
            className="btn btn-bg-light btn-sm btn-icon btn-active-light-primary"
            title="Download"
            onClick={() => {
              setLoading(true);
              downloadFileDispatch(
                { id: row.Id, destination: "bill" },
                `${row.BillNumber}.${"pdf"}`,
                () => {
                  setLoading(false);
                }
              );
            }}
          > */}
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            //onClick={handleEditData}
            onClick={() => setShowTransactionModal(true)}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <AddTransactionShrt
              showTransaction={showTransactionModal}
              closeModal={closeModal}
              initialValuess={initialValues}
          />

          {loading ? (
            <>
              <div className="text-gray-600">
                <SmallLoader />
              </div>
            </>
          ) : (
            <GeneratePdfByClick
              billData={row}
              title="Invoice"
              className="btn btn-icon btn-bg-light btn-sm btn-active-light-primary"
              showIcon={true}
            />
            // <i className="fa-solid fa-download"></i>
            // <ImageDisplay
            //   path="media/task/download-arrow.png"
            //   altText="download-arrow"
            //   className="w-20px"
            //   errorPath="/media/task/download-arrow.png"
            // />
          )}
          
        </div>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      callback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, callback)),
  };
};

export default connect(null, mapDispatchToProps)(BillingTableRow);
