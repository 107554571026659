import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { emptyObject } from "app/helpers/helperFunction";
import {
  convertToFinalProformaInvoice,
  getProformaInvoice,
} from "app/reducers/proformaInvoice/proformaInvoiceAction";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ReimbursementInvoiceForm from "../ReimbursementInvoiceForm";
import FinalBillConvertWarning from "./FinalBillConvertWarning";
import ProformaInvoiceBillDetail from "../proformaInvoiceDetailComponent/ProformaInvoiceBillDetail";
import ProformaInvoiceBankDetail from "../proformaInvoiceDetailComponent/ProformaInvoiceBankDetail";
import ProformaInvoiceTaskDetail from "../proformaInvoiceDetailComponent/ProformaInvoiceTaskDetail";
import ProformaInvoiceTransactionDetail from "../proformaInvoiceDetailComponent/ProformaInvoiceTransactionDetail";
import { PageLink, PageTitle } from "_metronic/layout/core";
import { adminHomePage } from "../../main/admin/AdminMain";
import { reimbursementInvoiceListPage } from "../ReimbursementInvoiceList";

interface props {
  proformaInvoiceDispatch: Function;
  proformaInvoiceData: any;
  proformaInvoiceLoading: boolean;
  proformaInvoiceError: string;
  addProformaToFinal: Function;
  savedBillPdfDetails: any;
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  reimbursementInvoiceListPage,
  emptyObject,
];

const ProformaInvoiceDetail: FC<props> = ({
  proformaInvoiceDispatch,
  proformaInvoiceData,
  proformaInvoiceLoading,
  proformaInvoiceError,
  addProformaToFinal,
  savedBillPdfDetails,
}) => {
  const params = useParams();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showWarningModel, setShowwarningModel] = useState(false);

  const editBillHandler = () => setShowEditForm(true);

  const closeEditInvoiceModel = () => setShowEditForm(false);

  const addToFinalClickFun = () => setShowwarningModel(true);

  useEffect(() => {
    proformaInvoiceDispatch(params.id);
  }, []);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>
        Reimbursement Invoice Detail
      </PageTitle>
      <ErrorBoundary>
        {proformaInvoiceLoading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : proformaInvoiceError ? (
          <ErrorDisplay error={proformaInvoiceError} />
        ) : proformaInvoiceData?.success ? (
          <div>
            <div className="row mb-6">
              <div className="col-lg-12 col-md-8 col-xl-8 mb-6 mb-lg-6 mb-md-0 mb-xl-0">
                <ProformaInvoiceBillDetail
                  billDetails={proformaInvoiceData?.data?.records}
                  editBillClickFun={editBillHandler}
                  addToFinalClickFun={addToFinalClickFun}
                />
              </div>
              <div className="col-lg-12 col-md-4 col-xl-4 pe-0 pe-xl-3">
                <ProformaInvoiceBankDetail
                  firmDetails={proformaInvoiceData?.data?.records?.Firm}
                />
              </div>
            </div>
            <div className="row mb-6">
              <div className="col-xl-6 mb-6">
                <ProformaInvoiceTaskDetail
                  taskListDetails={
                    proformaInvoiceData?.data?.records?.Tasks || []
                  }
                />
                <ProformaInvoiceTransactionDetail
                  title="Receipts"
                  transactionData={
                    proformaInvoiceData?.data?.records?.Receipts || []
                  }
                />
              </div>
              <div className="col-xl-6">
                <ProformaInvoiceTransactionDetail
                  title="Payments"
                  transactionData={
                    proformaInvoiceData?.data?.records?.Payments || []
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <ReimbursementInvoiceForm
          showInvoice={{
            show: showEditForm,
            billId: Number(params.id) || undefined,
          }}
          closeModel={closeEditInvoiceModel}
        />
        <FinalBillConvertWarning
          apiDispatchFun={(callback: any) =>
            addProformaToFinal(params.id, () => {
              setShowwarningModel(false);
              callback();
            })
          }
          proformaInvoiceData={proformaInvoiceData?.data?.records}
          closeModel={() => setShowwarningModel(false)}
          showWarningModel={showWarningModel}
          savedBillPdfDetails={
            savedBillPdfDetails?.billPdf?.data?.records?.FileUrl
          }
        />
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    proformaInvoiceLoading: state.proformaInvoice.loading,
    proformaInvoiceData: state.proformaInvoice.proformaInvoice,
    proformaInvoiceError: state.proformaInvoice.error,
    savedBillPdfDetails: state.saveBillPdf,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    proformaInvoiceDispatch: (invoiceId: number) =>
      dispatch(getProformaInvoice(invoiceId)),
    addProformaToFinal: (data: any, successCallback: Function) =>
      dispatch(convertToFinalProformaInvoice(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProformaInvoiceDetail);
